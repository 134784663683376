.footer-v1 {
	.container-xl {
		max-width: 1320px;
	}

	.main-footer {
		background: url(RESOURCE/img/footer-bg.jpg) no-repeat 0 0;

		/*	background-color: var(--footer-bg-color);
     border-top: 1px solid var(--footer-border-top-color);*/
		color: var(--footer-font-color);
		background-size: cover;
		min-height: 300px;

		@media(max-width:767px) {
			background-size: auto;
		}


		.text-muted {
			color: var(--color-grey-normal) !important;

		}

		padding-left: 5vw;
		padding-right: 5vw;

		position: relative;

		.headline {
			font-size: 1.25rem;
			color: var(--color-primary);
			font-family: var(--title-font-family-main);
		}


		.logo {
			width: 250px;
			height: auto;
			margin-top: -40px;
			padding-bottom: 20px;
		}

		.v-office-logo {

			@media(min-width: 993px) {
				text-align: right;
			}

			@media(max-width: 993px) {
				text-align: left;
			}
		}

		.openings {

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--footer-font-color);
			font-size: 0.98rem;

			&:hover {
				text-decoration: none;
			}
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
					font-size: 16px;

					&:hover {
						color: #344f59;
					}
				}

				.fa {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #344f59;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}

		.paymentmethods {
			width: 100%;
			text-align: right;
			margin-top: 30px;

			.fa {
				color: var(--color-white);
				font-size: 2.3rem;
				padding-left: 10px;

			}
		}

		.f-col {
			@media(max-width: 767px) {
				background: hsla(0, 0%, 100%, .6);
				margin: 0 20px 1.75em;
			}

		}
	}

	.footer-links {
		list-style: none;

		&.contact-info {
			li {
				font-size: 15px;
				margin-bottom: 1rem;
			}
		}

		&.list-arrow {
			li {
				&:before {
					content: "\f054";
					color: #436573;
					font-family: 'FontAwesome';
					padding-right: 7px;
					font-weight: 300;
					font-size: 14px;
				}
			}

		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);

		a {
			color: var(--footer-copyright-font-color);
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		.v-office-logo {
			float: left;
			padding-right: 20px;

			img {
				width: 50px;
			}
		}
	}

}