.search-page-layout-v3 {
	background-color: var(--color-white);
	margin-top: 0;

	.results {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 30px;

		@media (min-width:1200px) {
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
		}

		/*	@media (max-width:992px) {
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
		} */

		@media (max-width:992px) {
			grid-template-columns: 1fr;
		}

	}

	.more-btn-panel {
		margin-top: 30px;
	}

	@media (min-width:1500px) {
		.container {
			max-width: 1320px;
		}

		.container-xl {
			max-width: 1320px;
		}
	}

}

.my-search-token-v2 {
	margin: 2px;
	padding: 2px 6px;
	display: inline-block;
	border: 1px solid var(--color-grey-normal);
	border-radius: 4px;
	background-color: var(--color-grey-light);
	font-size: 14px;
	cursor: pointer;
	position: relative;
	transition: all 0.5s ease;
	margin-right: 8px;

	.close {
		transition: all 0.5s ease;
		position: absolute;
		height: 13px;
		width: 13px;
		right: -7px;
		top: -7px;
		font-size: 14px;
		text-align: center;
		background-color: var(--color-primary);
		color: #fff;
		border-radius: 50%;
		font-weight: 400;
		line-height: 13px;
		opacity: 0;
	}

	&:hover {
		border: 1px solid var(--color-primary);
		background-color: var(--color-white);

		.close {
			opacity: 1;
		}
	}
}